<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-form-textbox-password',
          title: 'Secure Credentials',
          text: 'Securely store all your passwords in one place. You never have to worry about your passwords getting compromised.',
        },
        {
          icon: 'mdi-credit-card-lock',
          title: 'Secure Banking',
          text: 'Securely store your credit card and banking information to retrieve it easily whenever you want.',
        },
        {
          icon: 'mdi-file-lock',
          title: 'Secure Files',
          text: 'Store your files containing sensitive information. We will use PBKDF-2 encryption to secure the files.',
        },
        {
          icon: 'mdi-send-lock',
          title: 'Secure Data Sharing',
          text: 'Securely share your saved information to other FirstVault users using true end-to-end encryption technology.',
        },
      ],
    }),
  }
</script>
